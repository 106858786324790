import React from "react"
import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SkillImage from "../components/SkillImage"
import Fade from "../components/Fade"
import useStyles from "../styles/skill"

const languages = [
  "html",
  "css",
  "javascript",
  "typescript",
  "c.sharp",
  "sql",
  "git"
]
const modules = [
  "apollo",
  "babel",
  "bootstrap",
  "ejs",
  "express",
  "gatsby",
  "graphql",
  "jest",
  "jquery",
  "jwt",
  "material.ui",
  "next",
  "node.",
  "nodemailer",
  "nuxt",
  "passport",
  "react",
  "redux",
  "semantic.ui",
  "sequelize",
  "vue",
  "webpack"
]
const database = [
  "contentful",
  "firebase",
  "mongodb",
  "mysql",
  "postgres",
  "redis",
  "sqlite"
]

interface Props {
  uri: string
  data: any
}

const Skills: React.FC<Props> = props => {
  const { data } = props
  const c = useStyles()
  return (
    <Layout uri={props.uri}>
      <SEO title="Skills" />
      <Fade>
        <main className={c.main}>
          <section className={c.section}>
            <div style={{ display: "flex" }}>
              <Typography className={c.typography} align="center" variant="h5">
                programming language
              </Typography>
            </div>
            <div className={c.skillImageContainer}>
              {languages.map(l => (
                <SkillImage key={l} name={l} width="60px" />
              ))}
            </div>
          </section>

          <section className={c.section}>
            <div style={{ display: "flex" }}>
              <Typography className={c.typography} align="center" variant="h5">
                module, framework, library
              </Typography>
            </div>
            <div className={c.skillImageContainer}>
              {modules.map(l => (
                <SkillImage
                  key={l}
                  name={l}
                  custom={{ flexGrow: 1 }}
                  width="60px"
                />
              ))}
            </div>
          </section>

          <section className={c.section}>
            <div style={{ display: "flex" }}>
              <Typography className={c.typography} align="center" variant="h5">
                database
              </Typography>
            </div>
            <div className={c.skillImageContainer}>
              {database.map(l => (
                <SkillImage key={l} name={l} width="60px" />
              ))}
            </div>
          </section>
        </main>
      </Fade>
    </Layout>
  )
}

export default Skills
