import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      margin: "2em 0 0"
    },
    section: {
      margin: "0 auto 3em",
      maxWidth: 600
    },
    typography: {
      transition: "all .3s ease",
      borderRadius: "20px",
      fontSize: "1.3rem",
      margin: "0 auto 1.5rem 0",
      [theme.breakpoints.down(500)]: {
        fontSize: "1rem"
      }
    },
    skillImageContainer: {
      display: "flex",
      flexWrap: "wrap"
    }
  })
)
